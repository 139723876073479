// import { useDispatch } from "react-redux";
import { dashboardAddressSearchReset, dashboardSinglePropertyDetailsReset } from "../../src/state/dashboardViewAction";
import { singlePropertyClear } from "../../src/state/detailSlice";
import * as amplitude from '@amplitude/analytics-browser';
import { Identify, identify } from '@amplitude/analytics-browser';
import { setOptOut } from '@amplitude/analytics-browser';

export function handleCheckBrokarage(id, userDetails) {

  if (id == 1) {
    if (userDetails?.cb_active !== true) {
      return true
    }
    else {
      return false
    }
  }
  if (id == 2) {
    if (userDetails?.kw_active !== true) {
      return true
    }
    else {
      return false
    }
  }
  if (id == 3) {
    if (userDetails?.co_active !== true) {
      return true
    }
    else {
      return false
    }
  }
  if (id == 4) {
    if (userDetails?.in_active !== true) {
      return true
    }
    else {
      return false
    }
  }
  if (id == 5) {
    if (userDetails?.ta_active !== true) {
      return true
    }
    else {
      return false
    }
  }
}


export function ResetPropertyDetails(dispatch) {

  dispatch(dashboardAddressSearchReset());
  dispatch(singlePropertyClear());
  dispatch(dashboardSinglePropertyDetailsReset());
}

const identifyObj = new Identify();
export function myFunction() {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf("Edg") != -1) {
    return 'Edge';
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return 'Firefox';
  } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
    return 'IE';
  } else {
    return 'unknown';
  }
};
const setCommonAttributes = (identifyObj, login, location) => {
  const isGuest = login?.role === 'user' ? 'Buyer/Seller' : login?.role === 'agent' ? 'Agent' : 'Guest';
  identifyObj.set('Location', location);
  identifyObj.set('User Type', isGuest);
  identifyObj.set('User Name', login?.name ?? '');
  identifyObj.set('User Email', login?.email ?? '');
  identifyObj.set('Browser', myFunction());
}

export function pageViewAnalytics(route, login, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    user_id: login?._id ?? '',
    page_source: route,
  });

  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}

export function DashboardFilter(route, login, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    user_id: login?._id ?? '',
    page_source: route,
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}

export function Mylistdetails(route, login, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    user_id: login?._id ?? '',
    page_source: route,
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}
export function SellerPages(route, login, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    user_id: login?._id ?? '',
    page_source: route,
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}
export const setUserIdAnalytics = (login, location) => {
  setOptOut(false);
  amplitude.setUserId(login?.data?.user?._id ?? '');
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}
export function LoginPage(route, login, location) {
  setOptOut(false);
  amplitude.setUserId(login?._id ?? '');
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}
export function AgentRelease(route, login, details, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    user_id: login?._id ?? '',
    page_source: route,
    agent_Name: details?.agent_id?.name ?? '',
    Agent_office: details?.agent_id?.meta_id?.office_name ?? ''
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}
export function LikeDislike(route, login, details, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    user_id: login?._id ?? '',
    page_source: route,
    Property_Name: details?.address ?? '',
    Property_status: details?.listing_status == 0 ? "Draft" : details?.listing_status == 1 ? "Active" : details?.listing_status == 2 ? "Active Contingent" : details?.listing_status == 3 ? "Exclusive" : details?.listing_status == 4 ? "Pending" : details?.listing_status == 5 ? "Sold" : details?.listing_status == 6 ? "Expired" : details?.listing_status == 7 ? "Cancelled" : details?.listing_status == 8 ? "Withdrawn" : 'Off Market' ?? ''
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}
export function PropertyClick(route, login, details, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    user_id: login?._id ?? '',
    page_source: route,
    Property_Name: details?.address ?? '',
    Property_status: details?.listing_status == 0 ? "Draft" : details?.listing_status == 1 ? "Active" : details?.listing_status == 2 ? "Active Contingent" : details?.listing_status == 3 ? "Exclusive" : details?.listing_status == 4 ? "Pending" : details?.listing_status == 5 ? "Sold" : details?.listing_status == 6 ? "Expired" : details?.listing_status == 7 ? "Cancelled" : details?.listing_status == 8 ? "Withdrawn" : 'Off Market' ?? '',
    Property_price: details?.price?.$numberDecimal ?? '',
    Property_beds: details?.beds ?? '',
    Property_baths: details?.baths ?? '',
    Property_sq_feet: details?.square_feet?.$numberDecimal ?? '',
    Property_listingAgent: details?.listing_agent_id?.name ?? '',
    Property_office_name: details?.listing_agent_id?.office_name ?? '',
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}

export function ChatData(route, login, details, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    user_id: login?._id ?? '',
    page_source: route,
    recipient_ID: login?._id ?? '',
    sender_ID: details?._id ?? '',
    browser: myFunction()
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}
export function AddpropertyData(route, login, details, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    user_id: login?._id ?? '',
    page_source: route,
    Property_Name: details?.address ?? '',
    Property_status: details?.listing_status == 0 ? "Draft" : details?.listing_status == 1 ? "Active" : details?.listing_status == 2 ? "Active Contingent" : details?.listing_status == 3 ? "Exclusive" : details?.listing_status == 4 ? "Pending" : details?.listing_status == 5 ? "Sold" : details?.listing_status == 6 ? "Expired" : details?.listing_status == 7 ? "Cancelled" : details?.listing_status == 8 ? "Withdrawn" : 'Off Market' ?? '',
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}

export function SearchResult(route, login, details, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    browser: myFunction(),
    user_id: login?._id ?? '',
    page_source: route,
    city_name: details
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}
export function FindagentSearch(route, login, details, location) {
  amplitude.logEvent(route, {
    timestamp: new Date().toISOString(),
    browser: myFunction(),
    user_id: login?._id ?? '',
    page_source: route,
    Agent_name: details
  });
  setCommonAttributes(identifyObj, login, location);
  identify(identifyObj);
}
export const getListingStatus = (status) => {
  switch (parseInt(status)) {
    case 0:
      return "Draft";
    case 1:
      return "Active";
    case 2:
      return "Active Contingent";
    case 3:
      return "Exclusive";
    case 4:
      return "Pending";
    case 5:
      return "Sold";
    case 6:
      return "Expired";
    case 7:
      return "Cancelled";
    case 8:
      return "Withdrawn";
    case 10:
      return "Off Market";
    default:
      return "Off Market";
  }
};
export const homeTypeDetails = (homeType) => {
  // console.log("homeType", homeType)

  switch (homeType) {
    case "101":
      return "Single Family";
    case "102":
      return "Condominium";
    case "702":
      return "Multi Family";
    case "103":
      return "Townhome";
    case "403":
      return "Lots / Land";
    case "105":
      return "Manufactured";
    case "109":
      return "Others";
    default:
      return "";
  }
};

export const formatPrice = (num) => {
  if (num === undefined) {
      return null
  } else {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
export const homeTypeConversion = [
  { name: 'Single Family', value: "101" },
  { name: 'Town Home', value: "103" },
  { name: 'Condo', value: "102" },
  { name: 'Lots Land', value: "403" },
  { name: 'Multi Family', value: "702" },
  { name: 'Manufactured', value: "105" },
  { name: 'Others', value: "109" },
];
export const getStatusColor = (status) => {
  switch (status) {
      case 0:
          return "orange";
      case 1:
      case 2:
          return "#03B45B";
      case 4:
          return "yellow";
      case 5:
          return "red";
      case 6:
          return "gray";
      case 7:
          return "red";
      case 8:
          return "gray";
      case 9:
          return "#0490fb";
      case 10:
          return "gray";
      default:
          return "gray";
  }
};
